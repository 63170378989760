import React, { useState } from 'react';
import { allStates, FilterCityInput } from '../styles/GlobalStyles';
import { Search, Exit } from '../assets';
import { useNavigate } from 'react-router-dom';

function ChooseState(props) {
    const utmSource = props.utmSource;
    const utmMedium = props.utmMedium;
    const utmCampaign = props.utmCampaign
    const [options, setOptions] = useState([]);
    const [state, setState] = useState("");
    const navigate = useNavigate();

    const changeState = (val) => {
        setState(val);

        const possibleStates = [];
        const lowerCaseVal = val.toLowerCase();
        for (let index = 0; index < allStates.length; index++) {
            const element = allStates[index];
            const stateName = element.name;
            const lowerCaseStateName = stateName.toLowerCase();
            if ( lowerCaseStateName.includes(lowerCaseVal) ) {
                possibleStates.push(element);
            }
        };
        setOptions(possibleStates);
    };

    const selectState = async(item) => {
        const utmData = utmSource === null ? null : {
            utmSource: utmSource,
            utmMedium: utmMedium,
            utmCampaign: utmCampaign
        }
        navigate(`/properties/${item.abbreviation}`, {
            state: {
                utmData: utmData
            }
        });
    };

    const resetCity = () => {
        setState("");
        setOptions([]);
    };

    return (
        <div className="relative-container">
            <FilterCityInput
                value={state}
                type="text"
                id="state-input"
                placeholder="Search for any state in the U.S..."
                onChange={(text) => changeState(text.target.value)}
                disabled={false}
            />
            <img
                src={state === "" ? Search : Exit}
                className={state === "" ? "state-search-icon" : "state-exit-icon"}
                alt="Search"
                onClick={() => state === "" ? null : resetCity()}
            />
            {
                options.length > 0 && state !== "" ?
                    <div className="state-filtering-outer-container">
                        {
                            options.map((item, index) =>
                                <div 
                                    className="state-filtering-element"
                                    key={index}
                                    onClick={() => selectState(item)}
                                >
                                    <span className="body-regular colour-primary">
                                        {item.name}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                :
                null
            }
        </div>
    )
};

export default ChooseState;