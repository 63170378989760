import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const fontFamily = 'Rubik';

export const colour = {
	beigeBeige01: "#FFF9F2",
	beigeBeige02: "#FFF3E7",
	beigeBeige03: "#F2E1D0",
	beigeBeige04: "#CFBCAD",

	blueBlue01: "#BCCAD4",
	blueBlue02: "#748EA0",
	blueBlue03: "#2F4858",
	blueBlueTransparent: "#456DB980",

	brownBrown01: "#512E18",

	greenGreen01: "#7FD1AE",
	greenGreen02: "#68AB8E",
	greenGreen03: "#00A8437F",
	greenGreen04: "#6EF0A27F",
	greenGreen05: "#BDFF00B2",

	grayScaleBlack: "#292621",
	grayScaleGray01: "#DFD9D3",
	grayScaleGray02: "#989793",
	grayScaleGray03: "#716E69",
	grayScaleGray04: "#DFD9D399",
	grayScaleWhite: "#FFFEFC",

	indigoIndigo00: "#AFC0FCB2",
	indigoIndigo01: "#456DB97F",
	indigoIndigo02: "#141B5999",

	redRed01: "#FE8A80",
	redRed02: "#CC564B",
	redRed01Transparent: "#FE8A8033",

	purplePurple01: "#9847FF99",

	pinkPink01: "#FF4A88B2",

	orangeOrange01: "#FF955BB2",
	orangeOrange02: "#FFCD70B2",
	orangeOrange03: "#FF955B",

	yellowYellow01: "#E8DA78",
	yellowYellow02: "#F8DB02BF",
	yellowYellow03: "#FFE94AB2",
	yellowYellow04: "#FFE94A",
	yellowYellowTransparent: "#FFE94AB3",

	cyanCyan01: "#39DBFFB2",

	textPrimary: "#292621",
	textSecondary: "#716E69",
	textTertiary: "#989793",

	heatmapHot: {red: 84, green: 3, blue: 212},
	heatmapCold: {red: 255, green: 237, blue: 70},

	heatmapHotHex: "#5304D4",
	heatmapColdHex: "#FFED46"
}

export const mapColours = {
	mapColorsMapZoneA: colour.greenGreen03,
	mapColorsMapZoneB: colour.greenGreen04,
	mapColorsMapZoneBC: colour.yellowYellow02,
	mapColorsMapZoneC: colour.indigoIndigo01,
	mapColorsMapZoneD: colour.indigoIndigo02,
	mapColorsMapZoneInactive: colour.grayScaleGray04,

	mapColorsMapGentrificationGentrified: colour.pinkPink01,
	mapColorsMapGentrificationInProgress: colour.orangeOrange01,
	mapColorsMapGentrificationEarlySigns: colour.yellowYellow03,
	mapColorsMapGentrificationLittleNone: colour.indigoIndigo00,

	mapColorsMapHotspotHipster: colour.purplePurple01,
	mapColorsMapHotspotCollege: colour.greenGreen05,
	mapColorsMapHotspotAffordable: colour.cyanCyan01,

	mapColorsTransparentYellow: colour.yellowYellowTransparent,
	mapColorsTransparentBlue: colour.blueBlueTransparent,
}

export const FilterCityInput = styled(TextField) ({
	'& label': {
		color: "#F5F6FA",
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '15px',
		fontWeight: 400,
		lineHeight: '18px',
		letterSpacing: '0em',
		textAlign: 'right'
	},
	'& label.Mui-focused': {
		color: "#F5F6FA",
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.blueBlue02
	},
	'& .MuiOutlinedInput-root': {
			fontFamily: fontFamily,
			color: colour.textPrimary,
			fontWeight: 400,
			backgroundColor: "#FFFEFC",
			borderRadius: '2px',
			height: '40px',
			border: `0.5px solid ${colour.blueBlue02}`,
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.blueBlue02
		},
		'&.Mui-focused fieldset': {
			border: 'none'
		}
	},
	width: '100%'
});

export const allStates = [
	{ abbreviation: "AL", name: "Alabama" },
	// { abbreviation: "AK", name: "Alaska" },
	{ abbreviation: "AZ", name: "Arizona" },
	{ abbreviation: "AR", name: "Arkansas" },
	{ abbreviation: "CA", name: "California" },
	{ abbreviation: "CO", name: "Colorado" },
	{ abbreviation: "CT", name: "Connecticut" },
	{ abbreviation: "DE", name: "Delaware" },
	{ abbreviation: "FL", name: "Florida" },
	{ abbreviation: "GA", name: "Georgia" },
	// { abbreviation: "HI", name: "Hawaii" },
	{ abbreviation: "ID", name: "Idaho" },
	{ abbreviation: "IL", name: "Illinois" },
	{ abbreviation: "IN", name: "Indiana" },
	{ abbreviation: "IA", name: "Iowa" },
	{ abbreviation: "KS", name: "Kansas" },
	{ abbreviation: "KY", name: "Kentucky" },
	{ abbreviation: "LA", name: "Louisiana" },
	{ abbreviation: "ME", name: "Maine" },
	{ abbreviation: "MD", name: "Maryland" },
	{ abbreviation: "MA", name: "Massachusetts" },
	{ abbreviation: "MI", name: "Michigan" },
	{ abbreviation: "MN", name: "Minnesota" },
	{ abbreviation: "MS", name: "Mississippi" },
	{ abbreviation: "MO", name: "Missouri" },
	{ abbreviation: "MT", name: "Montana" },
	{ abbreviation: "NE", name: "Nebraska" },
	{ abbreviation: "NV", name: "Nevada" },
	{ abbreviation: "NH", name: "New Hampshire" },
	{ abbreviation: "NJ", name: "New Jersey" },
	{ abbreviation: "NM", name: "New Mexico" },
	{ abbreviation: "NY", name: "New York" },
	{ abbreviation: "NC", name: "North Carolina" },
	{ abbreviation: "ND", name: "North Dakota" },
	{ abbreviation: "OH", name: "Ohio" },
	{ abbreviation: "OK", name: "Oklahoma" },
	{ abbreviation: "OR", name: "Oregon" },
	{ abbreviation: "PA", name: "Pennsylvania" },
	{ abbreviation: "RI", name: "Rhode Island" },
	{ abbreviation: "SC", name: "South Carolina" },
	{ abbreviation: "SD", name: "South Dakota" },
	{ abbreviation: "TN", name: "Tennessee" },
	{ abbreviation: "TX", name: "Texas" },
	{ abbreviation: "UT", name: "Utah" },
	{ abbreviation: "VT", name: "Vermont" },
	{ abbreviation: "VA", name: "Virginia" },
	{ abbreviation: "WA", name: "Washington" },
	{ abbreviation: "WV", name: "West Virginia" },
	{ abbreviation: "WI", name: "Wisconsin" },
	{ abbreviation: "WY", name: "Wyoming" }
];

export const defaultUserData = {
	settings: {
		recurringCosts: [
			{
				label: "Insurance",
				value: "",
				default: true,
				placeholder: "city based",
				info: "The default is based on the average for each city according to Zillow.",
				rentPercentage: false
			},
			{
				label: "Property taxes",
				value: "",
				default: true,
				placeholder: "city based",
				info: "The default is based on Zillow's property tax estimates for each property, which usually does NOT take into account any tax increase after you purchase the property. Please check county websites for this data (we're working on aggregating them)!",
				rentPercentage: false
			},
			{
				label: "Vacancy Rate Allocation",
				value: "",
				default: true,
				placeholder: "city based",
				info: "The default is based on the average vacancy rate for the city according to FRED (Federal Reserve Economic Data)...not just some guy called Fred.",
				rentPercentage: true
			},
			{
				label: "Management fee",
				value: "",
				default: true,
				placeholder: "9",
				info: "The default is 9% of your rental income.",
				rentPercentage: true
			},
			{
				label: "Maintenance",
				value: "",
				default: true,
				placeholder: "2",
				info: "The default is 2% of your rental income.",
				rentPercentage: true
			},
			{
				label: "Other costs",
				value: "",
				default: true,
				placeholder: "0",
				info: "The default is 0%. Use this to put money aside for a rainy day, like fixing the roof when it leaks in 5 years from now!",
				rentPercentage: true
			}
		],
		initialCosts: [
			{
				label: "Down payment",
				value: "",
				placeholder: "10"
			},
			{
				label: "Interest rate",
				value: "",
				default: true,
				placeholder: "3.5",
				info: ""
			},
			{
				label: "Balloon payment years",
				value: "",
				default: true,
				placeholder: "5",
				info: ""
			}
		],
		loanType: "30",
		customCities: [],
		mortgage: true,
		rehabCosts: {
			value: "",
            default: true
		}
	},
	options: {
		adu: false,
		allZips: true,
		bathrooms: 1,
		bedrooms: 1,
		budgetRange: [0, 1000000000],
		constructionYears: ["1900", "2024"],
		extraBedroom: false,
		hideFixerUppers: false,
		offMarketFilters: [],
		onlyFixerUppers: false,
		paymentInfo: {
			downPayment: 20,
			interestRate: 7,
			loanType: 0,
			mortgage: true,
			paymentType: "mortgage"
		},
		priceReduction: false,
		priceReductionIndex: 0,
		propTypeMislabelledFlag: false,
		propertyTypes: [
			{
				code: "MultiFamily",
				included: false,
				property: "Multi-Family"
			},
			{
				code: "SingleFamily",
				included: true,
				property: "Single Family Home"
			}
		],
		sellerFinancing: false,
		squareFootage: [750, 4000],
		units: 1,
		zips: [],
		startDate: new Date()
	}
};

export const FormInput = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		transform: 'none',
		maxWidth: 'none',
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '16px',
		top: '4px',
		left: '8px',
		padding: '0px'
	},
	'& label.Mui-focused': {
		color: colour.grayScaleBlack,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		paddingRight: '8px',
		marginTop: 'auto',
		lineHeight: '20px',
		height: 'auto'
	},
	'& .MuiInputAdornment-root': {
		marginTop: 'auto',
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '2px',
		height: '40px',
		paddingLeft: '8px',
		paddingBottom: '2px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
			top: '0px',
			borderRadius: '1px'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
});

// Formatting
export const percentage = new Intl.NumberFormat('en-US', {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 1,
});

export const percentageCompact = new Intl.NumberFormat('en-US', {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const formatterLong = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	notation: 'standard'
});

export const formatterCompact = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	notation: 'compact'
});

export const numberFormatter = new Intl.NumberFormat('en-US');