import React, { useEffect } from 'react';
import '../styles/Home.css';
import { Header, Footer, ChooseState } from '../components';
import { useSearchParams } from 'react-router-dom';

function Home() {

    const [searchParams] = useSearchParams();
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');


    useEffect(() => {
        document.title = "Creative FSBO";
    }, []);

    return (
        <div className="home-outer-container">
            <Header />
            <div className="home-inner-container">
                <div className="home-title-container margin-x-small">
                    <h1 className="display-xl-semibold colour-primary text-align-center margin-large">
                        <span className="text-link">
                            FREE
                        </span>
                        {" "}Creative Finance Leads
                    </h1>
                    <h2 className="body-regular colour-secondary text-align-center">
                        100% Free list of FSBO listings for creative finance. Updated daily.
                    </h2>
                </div>
                <div className="home-title-container margin-x-large">
                    <span className="body-semibold colour-primary block-text text-align-center">
                        Seller-finance leads • Subto leads
                    </span>
                </div>
                <div className="home-details-container margin-x-large">
                    <span className="body-regular colour-primary block-text text-align-center margin-x-small">
                        1. Pick a state
                    </span>
                    <span className="body-regular colour-primary block-text text-align-center margin-x-small">
                        2. Enter your email
                    </span>
                    <span className="body-regular colour-primary text-align-center block-text">
                        3. Get a CSV to your inbox
                    </span>
                </div>
                <div className="home-body-container">
                    <ChooseState
                        utmSource={utmSource}
                        utmMedium={utmMedium}
                        utmCampaign={utmCampaign}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Home;