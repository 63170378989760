import React from 'react';
import '../styles/CreativeTooltip.css';
import { Beak } from '../assets';

function CreativeTooltip() {
    return (
        <div className="creative-tooltip-container">
            <img
                src={Beak}
                className="creative-tooltip-beak"
                alt="Beak"
            />
            <h3 className="body-semibold colour-primary margin-x-small">
                Seller-finance <span className="body-regular">= Property owned free & clear</span>
            </h3>
            <h3 className="body-semibold colour-primary margin-x-small">
                Subto <span className="body-regular">= Estimated interest rate on loan is {"<"}5%</span>
            </h3>
            <h3 className="body-semibold colour-primary">
                Other <span className="body-regular">= All other FSBOs</span>
            </h3>
        </div>
    );
};

export default CreativeTooltip;