import React, { useEffect } from 'react';
import '../styles/Success.css';
import { Header, Footer } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { numberFormatter } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';
import { PrimaryButton } from '../buttons';

function Success() {

    const location = useLocation();
    const state = location.state;
    const email = state === null ? null : state.email;
    const listings = state === null ? null : state.listings;
    const fsboState = state === null ? null : state.fsboState;
    const supportEmail = "info@creativefsbo.com";
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Creative FSBO | Success";

        if ( state === null ) {
            navigate("/");
        }
    }, [navigate, state]);

    const contactUs = () => {
        recordEvent("Success - Contact Us", {});
        window.open(`mailto:${supportEmail}`, "_blank");
    };

    const bookDemo = () => {
        recordEvent("Success - Book Demo", {});
        window.open("https://calendly.com/liam-maher/creative-fsbo-tool", "_blank");
    };

    return (
        <div className="success-outer-container">
            <Header />
            {
                state === null ?
                null
                :
                <div className="success-inner-container">
                    <div className="success-title-container margin-x-x-large">
                        <span className="success-star-text block-text text-align-center margin-large">
                            ✨
                        </span>
                        <h1 className="heading-large-semibold colour-primary margin-large">
                            Success!
                        </h1>
                        <span className="body-regular colour-primary block-text text-align-center margin-large">
                            Sent <span className="body-semibold">{numberFormatter.format(listings)}</span> FSBO listings found in <span className="body-semibold">{fsboState}</span> to <span className="text-link">{email}</span>
                        </span>
                        <span className="body-regular colour-secondary block-text text-align-center">
                            It should take about 30 seconds to receive our email. 
                            If you can’t find it, check your spam box.{" "}
                            <span onClick={() => contactUs()} className="colour-link">Contact us</span> if you don’t receive it.
                        </span>
                    </div>
                    <div className="success-body-container">
                        <h2 className="heading-large-semibold colour-primary text-align-center margin-large">
                            Want to get FSBO listings every day, generate offer letters, & more? 
                        </h2>
                        <span className="body-regular colour-secondary block-text text-align-center margin-large">
                            Book a demo to get a personal tour of our new tool.
                        </span>
                        <div className="success-button-container">
                            <PrimaryButton
                                text="Book demo"
                                cta={bookDemo}
                                disabled={false}
                                size="medium"
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default Success;