import React, { useEffect, useState } from 'react';
import '../styles/GetProperties.css';
import { Header, Footer, CreativeTooltip, PropertiesTable } from '../components';
import { allStates, defaultUserData, FormInput, numberFormatter } from '../styles/GlobalStyles';
import { getAPIGatewayListings, cloudFunctionV2, setData, makeId, submitProperties, generateSheetData } from '../functions';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { PrimaryButton } from '../buttons';
import moment from 'moment';

function GetProperties() {

    const params = useParams();
    const abbreviation = params.id;
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [stateName, setStateName] = useState("");
    const [email, setEmail] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [leads, setLeads] = useState([0, 0]);
    const [disabled, setDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const propertyLimit = 5;
    const location = useLocation();
    const state = location.state;
    const utmData = state === null ? null : state.utmData
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Creative FSBO | ${abbreviation}`;

        const fetchProperties = async() => {
            const propertyTypes = [{
                code: "MultiFamily",
                included: true,
                property: "Multi-Family",
                abbreviation: "MFH"
            },
            {
                code: "SingleFamily",
                included: true,
                property: "Single-Family",
                abbreviation: "SFH"
            }];

            const stateIndex = allStates.findIndex(e => e.abbreviation === abbreviation);
            if ( stateIndex === -1 ) {
                navigate("/");
                return;
            }
            setStateName(allStates[stateIndex].name);

            const preferences = defaultUserData.settings;
            const newFilters = {
                adu: false,
                bathrooms: 1,
                bedrooms: 1,
                budgetRange: [0, 10000000],
                constructionYears: [1800, 2024],
                squareFootage: [0, 10000000],
                units: 1,
                propTypeMislabelled: false,
                priceReduction: false,
                extraBedroom: false,
                propertyTypes: propertyTypes,
                hideFixerUppers: false,
                onlyFixerUppers: false,
                priceReductionIndex: 0,
                neighbourhoodGradeMin: 1,
                neighbourhoodGradeMax: 8,
                fsbo: true,
                inherited: false,
                subto: false,
                sellerFinance: false
            };
            const getGatewayQueryString = await getAPIGatewayListings(preferences, false, abbreviation, newFilters);
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
            const gatewayData = {
                type: "gateway",
                resourceId: "listings",
                queryString: getGatewayQueryString
            };
            const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
            let queryData = [];
            if ( getGateway.status === 200 ) {
                const getGatewayBody = getGateway.body;
                if ( getGatewayBody.errorMessage !== undefined ) {
                }
                else {
                    queryData = getGatewayBody.props;
                }
            }
            else if ( getGateway.status === 404 ) {
                const customS3Location = getGateway.body.s3_location;
                const params = {
                    type: "s3",
                    s3Data: {
                        key: customS3Location,
                        bucketName: "residentialpropertydata"
                    }
                };
                const getS3Query = await cloudFunctionV2(gatewayURL, params);
                if ( getS3Query.status === 200 ) {
                    const getS3Body = getS3Query.body;
                    queryData = getS3Body.props;
                }
            };

            const sfLeads = queryData.filter(e => e.sellerFinancingFreeClearFlag === true);
            const subtoLeads = queryData.filter(e => e.subtoFlag === true);
            setLeads([sfLeads.length, subtoLeads.length]);
            setLoading(false);

            for (let index = 0; index < queryData.length; index++) {
                queryData[index].price = Number(queryData[index].price);
                queryData[index].financials.downPayment = 20;
                queryData[index].financials.closingCosts = queryData[index].price * 0.03;
                queryData[index].financials.initialCosts = ((queryData[index].financials.downPayment * queryData[index].price) / 100) + queryData[index].financials.closingCosts;

                queryData[index].datePostedTS = new Date(queryData[index].datePosted);
                const a = moment(new Date());
                const b = moment(queryData[index].datePostedTS);
                const dateDifference = a.diff(b, 'days');
                queryData[index].dateDifference = dateDifference;

                queryData[index].ownerAttributes.estMortgageBalance = Number(queryData[index].ownerAttributes.estMortgageBalance);
                queryData[index].ownerAttributes.estMortgagePayment = Number(queryData[index].ownerAttributes.estMortgagePayment);
                const remainingMortgage = queryData[index].ownerAttributes.estMortgageBalance;
                const equity = (queryData[index].price - remainingMortgage) / queryData[index].price;
                queryData[index].ownerAttributes.estEquity = equity;

            }
            setProperties(queryData);
        };

        fetchProperties();
    }, [abbreviation, navigate]);

    const onChangeEmail = (val) => {
        const lowerCase = val.toLowerCase();
        setEmail(lowerCase);
    };

    const complete = async() => {
        setDisabled(true);

        if ( properties.length === 0 ) {
            setErrorMessage("There are no properties to send");
            setDisabled(false);
            return;
        }

        const colRef = "Requests";
        const docRef = await makeId(15);
        const data = {
            abbreviation: abbreviation,
            date: new Date(),
            email: email,
            listings: properties.length,
            state: stateName,
            leads: leads,
            consultation: false,
            utmData: utmData
        };
        await setData(colRef, docRef, data);

        const sheetData = await generateSheetData(properties);
        const emailData = {
            abbreviation: abbreviation,
            email: email,
            properties: properties,
            state: stateName,
            subject: `Your ${stateName} FSBO Listings`,
            id: docRef,
            sheetData: sheetData,
            tabname: `${abbreviation} FSBO Listings`,
            pageURL: `${window.location.origin}/view-data/${docRef}`,
            utmData: utmData
        }
        const sendOutProperties = await submitProperties(emailData);
        if ( sendOutProperties.status === 200 ) {
            setErrorMessage("");
            navigate("/success", {
                state: {
                    email: email, 
                    listings: properties.length, 
                    fsboState: stateName 
                }
            });
        }
        else {
            setErrorMessage(sendOutProperties.error);
        }
        setDisabled(false);
    };

    return (
        <div className="get-properties-outer-container">
            <Header />
            <div className="get-properties-inner-container">
                {
                    loading === true ?
                    <div className="get-properties-loading-container relative-container">
                        <h1 className="heading-large-semibold colour-primary text-align-center">
                            Getting properties in {stateName}...
                        </h1>
                    </div>
                    :
                    <div className="get-properties-body-container">
                        <div className="get-properties-title-container margin-large">
                            <h1 className="heading-large-semibold colour-primary block-text text-align-center margin-large">
                                <span className="text-link">{numberFormatter.format(properties.length)}</span> FSBO listings in <span className="text-link">{stateName}</span>
                            </h1>
                            <h2 className="body-regular colour-secondary block-text text-align-center">
                                Submit your email address to receive the full list as a .CSV file to your inbox.
                            </h2>
                        </div>
                        <div className="get-properties-lead-types-container margin-large">
                            <span className="body-regular colour-primary block-text text-align-center subtext-margin-right">
                                {leads[0]} Seller-finance leads • {leads[1]} Subto leads • {properties.length - leads[0] - leads[1]} Other
                            </span>
                            <div className="relative-container">
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 16 16" 
                                    fill="none"
                                    className="get-properties-lead-types-icon cursor-pointer"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    <path 
                                        d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                        fill="black"
                                    />
                                    <path 
                                        d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                        fill="black"
                                    />
                                    <path 
                                        fillRule="evenodd" 
                                        clipRule="evenodd" 
                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                        fill="black"
                                    />
                                </svg>
                                {
                                    showTooltip === true &&
                                    <CreativeTooltip />
                                }
                            </div>
                        </div>
                        <div className="get-properties-form-container">
                            <div className="get-properties-input-container margin-medium">
                                <FormInput
                                    value={email}
                                    type="email"
                                    label="Email"
                                    id="email-input"
                                    placeholder="Email address"
                                    onChange={(text) => onChangeEmail(text.target.value)}
                                    autoCapitalize="off"
                                />
                            </div>
                            <div className="get-properties-button-container margin-medium">
                                <PrimaryButton
                                    text={disabled === true ? "Loading..." : "Send me the list"}
                                    cta={complete}
                                    disabled={loading === true ? true : email === "" ? true : disabled === true ? true : false}
                                    size="medium"
                                    leftIcon={null}
                                    leftIconClass=""
                                    rightIcon={null}
                                    alt=""
                                />
                            </div>
                            <div className="get-properties-form-message-container margin-x-large">
                                {
                                    errorMessage === "" ?
                                    <span className="body-regular colour-secondary block-text text-align-center">
                                        This should take about 30 seconds 🔥
                                    </span>
                                    :
                                    <span className="body-regular colour-error block-text text-align-center">
                                        There was an error - {errorMessage}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="get-properties-table-container">
                            <PropertiesTable
                                properties={properties}
                                propertyLimit={propertyLimit}
                            />
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
};

export default GetProperties;