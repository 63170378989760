import React, { useEffect, useState } from 'react';
import '../styles/ViewData.css';
import { Header, Footer } from '../components';
import { useParams, useNavigate } from 'react-router-dom';
import { getDocument, recordEvent, setMergeData } from '../functions';
import { numberFormatter } from '../styles/GlobalStyles';
import moment from 'moment';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { Download } from '../assets'; 

function ViewData() {
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [csvData, setCsvData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [downloaded, setDownloaded] = useState(false);

    useEffect(() => {
        const fetchData = async() => {
            const colRef = "Requests";
            const docRef = id;
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const data = queryData.data.data;
                if ( data.pageViewed === false ) {
                    data.pageViewed = true;
                    await setMergeData(colRef, docRef, data);
                }
                setCsvData(data);
                document.title = `Creative FSBO | View ${data.state} Data`;
            }
            else {
                navigate("/");
            }
            setLoading(false);
        };

        if ( id === null ) {
            navigate("/");
            setLoading(false);
        }
        else {
            fetchData();
        }
    }, [navigate, id]);

    const downloadCSV = async() => {
        recordEvent("Download CSV", {
            id: id
        });

        const colRef = "Requests";
        csvData.downloaded = true;
        const today = new Date();
        csvData.downloadTimes.push(today);
        await setMergeData(colRef, id, csvData);

        const url = csvData.url;
        const filename = `${csvData.state} - FSBO Listings.xlsx`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = (e) => {
            const blob = xhr.response;
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = filename; // Set the filename to the extracted name
            a.click();
        };
        xhr.send();
        setDownloaded(true);
    };

    const openCalendly = async() => {
        window.open("https://calendly.com/liam-maher/creative-fsbo-tool", "_blank");
        const colRef = "Requests";
        recordEvent("Open Calendly", {
            id: id
        });
        csvData.consultation = true;
        await setMergeData(colRef, id, csvData);
    };

    return (
        <div className="view-data-outer-container">
            <Header />
            <div className="view-data-inner-container">
                <div className="view-data-title-container">
                    <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                        {
                            loading === true ?
                            `Loading...`
                            :
                            `View & download data`
                        }
                    </h1>
                </div>
                {
                    csvData !== null ?
                    <div className="view-data-body-container">
                        <span className="body-regular colour-secondary block-text text-align-center margin-x-small">
                            {numberFormatter.format(csvData.listings)} FSBO listings found in {csvData.state}
                        </span>
                        <span className="body-regular colour-primary block-text text-align-center margin-x-large">
                            Data updated as of <span className="body-semibold">{moment.unix(csvData.date.seconds).format("DD MMMM YYYY")}</span>
                        </span>
                        <div className="view-data-buttons-row">
                            <div className="view-data-left-button-container">
                                <SecondaryButton
                                    text="Free consultation"
                                    cta={openCalendly}
                                    disabled={false}
                                    size="medium"
                                    leftIcon={null}
                                    leftIconClass=""
                                    rightIcon={null}
                                    alt=""
                                />
                            </div>
                            <div className="view-data-right-button-container">
                                <PrimaryButton
                                    text="Download CSV"
                                    cta={downloadCSV}
                                    disabled={false}
                                    size="medium"
                                    leftIcon={Download}
                                    leftIconClass="view-data-download-icon"
                                    rightIcon={null}
                                    alt="Download"
                                />
                            </div>
                        </div>
                        {
                            downloaded === true ?
                            <div className="view-data-download-message-container margin-top-medium">
                                <span className="body-regular colour-primary block-text text-align-center">
                                    If your download didn't work, try clicking the "Download CSV" button again.
                                    <br/>
                                    If it still won't work, it's likely that your browser's security settings are blocking the download.
                                </span>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
            </div>
            <Footer />
        </div>
    )
};

export default ViewData;