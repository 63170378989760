import React from 'react';
import '../styles/Footer.css';
import { recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    const viewDataSources = () => {
        recordEvent("View data sources", {
            source: "footer"
        });
        navigate("/data-sources");
    };

    const contactUs = () => {
        recordEvent('Contact Us', {
            page: "Footer"
        });
        window.location.href = `mailto:info@creativefsbo.com`
    };

    return (
        <footer className="footer-outer-container bg-colour-dark">
            <div className="footer-inner-container">
                <div className="footer-left-container">
                    <span className="body-regular colour-secondary block-text">
                        © CreativeFSBO 2024. All rights reserved.
                    </span>
                </div>
                <div className="footer-right-container">
                    <span
                        className="body-regular colour-white cursor-pointer block-text subtext-margin-right-x-x-x-x-large"
                        onClick={() => viewDataSources()}
                    >
                        Data sources
                    </span>
                    <span 
                        className="body-regular colour-white cursor-pointer block-text"
                        onClick={() => contactUs()}
                    >
                        Contact us
                    </span>
                </div>
            </div>
        </footer>
    )
};

export default Footer;