import React from 'react';
import './Buttons.css';

function SecondaryButton(props) {
    const text = props.text;
    const cta = props.cta;
    const disabled = props.disabled;
    const sizeClass = props.size === "medium" ? "size-medium" : props.size === "small" ? "size-small" : "size-large";
    const textSize = props.size === "medium" ? "body-semibold" : "label-semibold";
    const leftIcon = props.leftIcon;
    const leftIconClass = props.leftIconClass;
    const rightIcon = props.rightIcon;
    const rightIconClass = props.rightIconClass;
    const alt = props.alt;

    return (
        <div 
            className={"secondary-button button-radius-normal cursor-pointer " + (disabled === true ? "button-disabled " : "") + (sizeClass)}
            onClick={disabled === true ? null : cta}
        >
            <div className={`${sizeClass}-padding`}>
                {
                    leftIcon === null ?
                    null
                    :
                    <img
                        src={leftIcon}
                        className={leftIconClass}
                        alt={alt}
                    />
                }
                <span className={(textSize) + " text-link block-text text-align-center"}>
                    {text}
                </span>
                {
                    rightIcon === null ?
                    null
                    :
                    <img
                        src={rightIcon}
                        className={rightIconClass}
                        alt={alt}
                    />
                }
            </div>
        </div>
    )
};

export default SecondaryButton;