import React from 'react';
import '../styles/Header.css';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../assets';

function Header() {

    const navigate = useNavigate();
    const goHome = () => {
        navigate("/");
    };

    return (
        <header className="header-outer-container">
            <div className="header-inner-container">
                <div 
                    className="header-logo-container cursor-pointer"
                    onClick={() => goHome()}
                >
                    <div className="header-logo-image-container">
                        <img
                            src={Logo}
                            className="header-logo"
                            alt="Logo"
                        />
                    </div>
                    <div className="">
                        <h1 className="heading-large-regular colour-primary margin-x-x-small">
                            Creative<span className="heading-large-semibold">FSBO</span>
                        </h1>
                        <span className="body-regular colour-primary">
                            For-sale-by-owner listings daily
                        </span>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;