import React, { useEffect } from 'react';
import '../styles/DataSources.css';
import { Header, Footer } from '../components';
import { PrimaryButton } from '../buttons';
import { recordEvent } from '../functions';

function DataSources() {

    useEffect(() => {
        document.title = "Creative FSBO | Data Sources";
    }, []);

    const dataSources = [
        {
            title: "How often is the data updated?",
            description: "Every day",
        },
        {
            title: "What is a Seller Finance & Subto lead?",
            description: "Seller finance leads are properties owned free & clear. Subto leads are properties with a mortgage with an estimated interest rate of below 5%. We estimate the interest rate by looking at the date the mortgage was taken out, the mortgage balance, and the type of loan it was.",
        },
        {
            title: "Property data",
            description: "General property data such as Beds, Baths, Sq. Footage, Lot Size, Year Built, etc. comes from Zillow/Redfin/Realtor.",
        },
        {
            title: "Rent estimates",
            description: "Our rent estimates come from looking at all recently listed rental properties on Zillow/Redfin etc. and then using a machine learning model to determine which comps are the most similar based on Beds, Baths, Sq. Footage, Location etc. and then we do a weighted average across all comps.",
        },
        {
            title: "Costs",
            description: "Things like property taxes, insurance, HOA fees, etc. come from Zillow/Redfin/Realtor. As for Maintenance and Management fees we use industry standard estimates as a percentage of rent.",
        },
        {
            title: "Cash-flow and Cash on Cash Return",
            description: "We use our rent estimate, costs, purchase price, your down payment and interest rate to determine the Cash-flow & Cash on Cash Return.",
        },
        {
            title: "Equity, Mortgage Balances, Recent Deaths & Pre-Foreclosure",
            description: "This data all comes from Real Estate API and is mostly based on public records.",
        },
        {
            title: "Fixer Uppers",
            description: "We read the property description and look for keywords that suggest the property is a fixer-upper.",
        }
    ];

    const bookCall = () => {
        window.open("https://calendly.com/liam-maher/creative-fsbo-tool", "_blank");
        recordEvent("Open Calendly", {
            page: "Data sources"
        });
    };

    return (
        <div className="data-sources-outer-container">
            <Header />
            <div className="data-sources-inner-container">
                <div className="data-sources-title-container margin-x-large">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Data Sources
                    </h1>
                </div>
                <div className="data-sources-body-container">
                    {
                        dataSources.map((item, index) =>
                            <div 
                                className="data-sources-element-container margin-medium"
                                key={index}
                            >
                                <span className="heading-small-semibold colour-primary block-text margin-x-small">
                                    {item.title}
                                </span>
                                <span className="body-regular colour-primary">
                                    {item.description}
                                </span>
                            </div>
                        )
                    }
                    <div className="data-sources-book-call-container margin-top-x-large">
                        <span className="body-semibold colour-primary block-text margin-x-small">
                            Looking to purchase a property with creative finance?
                        </span>
                        <div className="data-sources-button-container">
                            <PrimaryButton
                                text={"Book a call"}
                                cta={bookCall}
                                disabled={false}
                                size="medium"
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DataSources;