import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/Home';
import GetProperties from './pages/GetProperties';
import Success from './pages/Success';
import Error404 from './pages/Error404';
import DataSources from './pages/DataSources';
import ViewData from './pages/ViewData';
import ScrollToTop from './components/ScrollToTop';
import './styles/App.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import allReducers from './reducers';
import mixpanel from 'mixpanel-browser';
import './styles/Variables.css';
import { init as initFullStory } from '@fullstory/browser';
import { PostHogProvider} from 'posthog-js/react'
const store = configureStore({ reducer: allReducers });

// Initialize Firebase
const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE).config);
getAnalytics(app);

mixpanel.init(JSON.parse(process.env.REACT_APP_MIXPANEL).key, {debug: false});
initFullStory({ orgId: JSON.parse(process.env.REACT_APP_FULLSTORY).orgId });

const options = {
	api_host: JSON.parse(process.env.REACT_APP_POSTHOG).host
};

const routing = (
	<Provider
	  store={store}
	>
	  	<BrowserRouter>
			<PostHogProvider 
				apiKey={JSON.parse(process.env.REACT_APP_POSTHOG).key}
				options={options}
			>
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/properties/:id" element={<GetProperties />} />
						<Route path="/data-sources" element={<DataSources />} />
						<Route path="/success" element={<Success />} />
						<Route path="/view-data/:id" element={<ViewData />} />
						<Route path="*" element={<Error404 />} />
					</Routes>
				</ScrollToTop>
			</PostHogProvider>
		</BrowserRouter>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode> {
		routing
	// } </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();