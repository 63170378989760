import { FullStory } from '@fullstory/browser';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { getFirestore, setDoc, doc, getDoc } from 'firebase/firestore';

export const cloudFunctionV2 = async(url, data) => {
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			if (error instanceof TypeError) {
				const res = {
					status: 406,
					error: "Load failed - network error"
				};
				resolve(res);
			}
		});
	});
};

export const getAPIGatewayListings = async(userSettings, offMarket, cityId, filters) => {
	const initialCosts = userSettings.initialCosts;
	const downPayment = initialCosts[0].value === "" ? 0.1 : Number(initialCosts[0].value) / 100;
	const interestRate = initialCosts[1].default === true ? 0.035 : (Number(initialCosts[1].value) / 100);

	const aduFlag = filters.adu === true ? 1 : 0;
	const bathrooms = filters.bathrooms;
	const bedrooms = filters.bedrooms;
	const budgetRange = filters.budgetRange;
	const constructionYears = filters.constructionYears[0] === "" || filters.constructionYears[1] === "" ? [1800, 2024] : filters.constructionYears;
	const squareFootage = filters.squareFootage;
	const units = filters.units;
	const propTypeMislabelled = filters.propTypeMislabelled === true ? 1 : 0;
	const priceReduction = filters.priceReduction === true ? 1 : 0;
	const extraBedroom = filters.extraBedroom === true ? 1 : 0;
	const multiFamIndex = filters.propertyTypes.findIndex(e => e.code === "MultiFamily");
	const singleFamIndex = filters.propertyTypes.findIndex(e => e.code === "SingleFamily");
	const multiFam = filters.propertyTypes[multiFamIndex].included;
	const singleFam = filters.propertyTypes[singleFamIndex].included;
	const propertyTypes = multiFam === true && singleFam === true ? ["SingleFamily", "MultiFamily"] : multiFam === true && singleFam === false ? ["MultiFamily"] : multiFam === false && singleFam === true ? ["SingleFamily"] : ["SingleFamily", "MultiFamily"];
	const priceChangeDaysMin = filters.priceReductionIndex < 5 ? 0 : filters.priceReductionIndex === 5 ? 30 : filters.priceReductionIndex === 6 ? 60 : 120;
	const priceChangeDaysMax = filters.priceReductionIndex > 4 ? 365 : filters.priceReductionIndex === 0 ? 1 : filters.priceReductionIndex === 1 ? 3 : filters.priceReductionIndex === 2 ? 7 : filters.priceReductionIndex === 3 ? 14 : 30;
	const neighbourhoodGradeMin = filters.neighbourhoodGradeMin !== undefined ? filters.neighbourhoodGradeMin : 1;
	const neighbourhoodGradeMax = filters.neighbourhoodGradeMax !== undefined ? filters.neighbourhoodGradeMax : 8;
	const fsbo = filters.fsbo === undefined ? false : filters.fsbo;
	const sellerFinancing = filters.sellerFinance === true ? 1 : 0;
	const subto = filters.subto === true ? 1 : 0;
	const hideFixerUppers = filters.hideFixerUppers === true ? 1 : 0;
	const onlyFixerUppers = filters.onlyFixerUppers === true ? 1 : 0;
	const inherited = filters.inherited === true ? 1 : 0;

	const body = {
		cityId: cityId,
		financials: {
			downPaymentRate: downPayment,
			interestRate: interestRate
		},
		filters: {
			extraBedroomFlag: extraBedroom,
			propertyTypes: propertyTypes,
			hideFixerUppersFlag: hideFixerUppers,
			onlyFixerUppersFlag: onlyFixerUppers,
			aduFlag: aduFlag,
			possibleMultiFamilyFlag: offMarket === true ? "None" : propTypeMislabelled,
			priceReductionFlag: offMarket === true ? "None" : priceReduction,
			priceChangeDaysMin: offMarket === true ? "None" : priceReduction === 0 ? 0 : priceChangeDaysMin,
			priceChangeDaysMax: offMarket === true ? "None" : priceReduction === 0 ? 365 : priceChangeDaysMax,
			constructionYearsMin: constructionYears[0],
			constructionYearsMax: constructionYears[1],
			bedroomsMin: bedrooms,
			bathroomsMin: bathrooms,
			sqftMin: squareFootage[0],
			sqftMax: squareFootage[1],
			unitsMin: offMarket === true ? "None" : units,
			priceMin: budgetRange[0],
			priceMax: budgetRange[1],
			neighborhoodGradeMin: neighbourhoodGradeMin,
			neighborhoodGradeMax: neighbourhoodGradeMax,
			fsbo: fsbo === true ? 1 : 0,
			freeClearFlag: 1,
			hideNewConstructionFlag: 1,
			sellerFinancingFreeClearFlag: sellerFinancing,
			subtoFlag: subto,
			inherited: inherited
		}
	};
	const formatPropertyTypes = body.filters.propertyTypes.length === 1 ? `'${body.filters.propertyTypes[0]}'` : `'${body.filters.propertyTypes[0]}','${body.filters.propertyTypes[1]}'`;
	const queryString = `cityId=${body.cityId}&filters={'propertyTypes':[${formatPropertyTypes}], 'sellerFinancingFreeClearFlag':${body.filters.sellerFinancingFreeClearFlag}, 'subtoFlag':${body.filters.subtoFlag}, 'fsboFlag':${body.filters.fsbo}, 'priceMin':${body.filters.priceMin}, 'priceMax':${body.filters.priceMax}, 'sqftMin':${body.filters.sqftMin}, 'sqftMax':${body.filters.sqftMax}, 'unitsMin':${body.filters.unitsMin}, 'bedroomsMin':${body.filters.bedroomsMin}, 'bathroomsMin':${body.filters.bathroomsMin}, 'constructionYearsMin':${body.filters.constructionYearsMin}, 'constructionYearsMax':${body.filters.constructionYearsMax}, 'hideFixerUppersFlag':${body.filters.hideFixerUppersFlag}, 'onlyFixerUppersFlag':${body.filters.onlyFixerUppersFlag}, 'inheritedFlag':${body.filters.inherited}, 'neighborhoodGradeMin':${body.filters.neighborhoodGradeMin}, 'neighborhoodGradeMax':${body.filters.neighborhoodGradeMax}}&financials={'downPaymentRate':${body.financials.downPaymentRate}, 'interestRate':${body.financials.interestRate}}`;
	const pathWithQueryString = `listings?${queryString}`;
	return pathWithQueryString;
};

export const recordEvent = (name, fields) => {
	mixpanel.track(name, fields);
	FullStory.event(name, fields);
	posthog.capture(name, fields);
};

export const setData = (colRef, docRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef), {
			data
		})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			console.log("Set data error = ", e);
			console.log("Set data error data = ", data);
			resolve(res);
		});
	});
}

export const makeId = (length) => {
    var id = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       id += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return id;
};

export const submitProperties = async(data) => {
	const url = "https://savecsv-ibaophrhrq-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const getCellKey = (label, excelIndex) => {
	const cellKey = [
		{
			label: "Sq. Footage",
			cell: `G${excelIndex}`
		},
		{
			label: "Monthly Profit",
			cell: `M${excelIndex}`
		},
		{
			label: "Up-Front Costs",
			cell: `N${excelIndex}`
		},
		{
			label: "Price",
			cell: `O${excelIndex}`
		},
		{
			label: "Price per sq. foot",
			cell: `N${excelIndex}`
		},
		{
			label: "Down Payment",
			cell: `Q${excelIndex}`
		},
		{
			label: "Interest Rate",
			cell: `R${excelIndex}`
		},
		{
			label: "Closing Costs",
			cell: `S${excelIndex}`
		},
		{
			label: "Renovation",
			cell: `T${excelIndex}`
		},
		{
			label: "Rent",
			cell: `U${excelIndex}`
		},
		{
			label: "Other Income",
			cell: `V${excelIndex}`
		},
		{
			label: "Mortgage",
			cell: `W${excelIndex}`
		},
		{
			label: "HOA",
			cell: `X${excelIndex}`
		},
		{
			label: "Insurance",
			cell: `Y${excelIndex}`
		},
		{
			label: "Property taxes",
			cell: `Z${excelIndex}`
		},
		{
			label: "Other costs",
			cell: `AD${excelIndex}`
		},
		{
			label: "Loan years",
			cell: `AE${excelIndex}`
		},
		{
			label: "Loan amount",
			cell: `AF${excelIndex}`
		},
		{
			label: "Payment Months",
			cell: `AG${excelIndex}`
		},
		{
			label: "Mortgage Balance",
			cell: `AL${excelIndex}`
		}
	];
	const index = cellKey.findIndex(e => e.label === label);
	return cellKey[index].cell;
};

export const generateSheetData = (rawData) => {
	const data = [];
	for (let index = 0; index < rawData.length; index++) {
		const element = rawData[index];
		const excelIndex = index + 2;

		const initialCostsCell = getCellKey("Up-Front Costs", excelIndex);
		const monthlyProfitCell = getCellKey("Monthly Profit", excelIndex);
		const priceCell = getCellKey("Price", excelIndex);
		const downPaymentCell = getCellKey("Down Payment", excelIndex);
		const closingCostCell = getCellKey("Closing Costs", excelIndex);
		const interestRateCell = getCellKey("Interest Rate", excelIndex);
		const renovationCell = getCellKey("Renovation", excelIndex);

		const rentCell = getCellKey("Rent", excelIndex);
		const otherIncomeCell = getCellKey("Other Income", excelIndex);
		const mortgageCell = getCellKey("Mortgage", excelIndex);
		const otherCostsCell = getCellKey("Other costs", excelIndex);
		const loanYearsCell = getCellKey("Loan years", excelIndex);
		const paymentMonthsCell = getCellKey("Payment Months", excelIndex);
		const loanAmountCell = getCellKey("Loan amount", excelIndex);
		const mortgageBalanceCell = getCellKey("Mortgage Balance", excelIndex);

		const sqFootageCell = getCellKey("Sq. Footage", excelIndex);
		const loanYears = 30;
		const downPayment = element.financials.downPayment;

		let skipTraceLink = "";
		if ( element.ownerAttributes.ownerType === "Company" ) {
            // corporate owner https://opencorporates.com
            const companyName = element.ownerAttributes.ownerName.replace(/ /g, "+");
            skipTraceLink = `https://opencorporates.com/companies?utf8=✓&utf8=✓&q=${companyName}&jurisdiction_code=&type=companies`;
        }
        else {
            const street = element.address.streetAddress.toLowerCase().replace(/ /g, "-");
            const city = element.address.city.toLowerCase().replace(/ /g, "-");
            const state = element.address.state.toLowerCase();
            skipTraceLink = `https://www.fastpeoplesearch.com/address/${street}_${city}-${state}`;
        }

		const newObject = {
			"Street": element.address.streetAddress,
			"City": element.address.city,
			"State": element.address.state,
			"ZIP Code": element.address.zipcode,
			"House Type": element.propertyTypeDimension,
			"Year Built": element.yearBuilt,
			"Sq. Footage": element.livingArea !== null ? element.livingArea : 1,
			"Bedrooms": element.bedrooms !== null ? element.bedrooms : 0,
			"Bathrooms": element.bathrooms !== null ? element.bathrooms : 0,
			"Seller Finance Lead": {
				t: "b",
				v: element.sellerFinancingFreeClearFlag
			},
			"Subto Lead": {
				t: "b",
				v: element.subtoFlag
			},
			"Cash on Cash": {
				t: "n",
				z: "0.00%",
				f: `((${monthlyProfitCell}*12)/${initialCostsCell})`
			},
			"Monthly Cash-Flow": {
				t: "n", 
				v: element.financials.monthlyProfit,
				z: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',
				f: `(${rentCell}+${otherIncomeCell})-SUM(${mortgageCell}:${otherCostsCell})`,
			},
			"Up-Front Costs": {
				t: "n",
				v: (((downPayment / 100) * element.price) + (element.price * element.financials.closingCosts)),
				z: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',
				f: `(${downPaymentCell}+${closingCostCell}+${renovationCell})`
			},
			"Price": {
				t: "n",
				v: element.price,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
			},
			"Price Per Sq. Foot": {
				t: "n",
				v: element.price / (element.livingArea !== null ? element.livingArea : 1),
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${priceCell}/${sqFootageCell})`
			},
			"Down Payment": {
				t: "n",
				v: ((downPayment / 100) * element.price),
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${downPayment}/100)*${priceCell}`
			},
			"Interest Rate": {
				t: "n",
				v: element.mortgage30us !== undefined ? element.mortgage30us / 100 : 0.0718,
				z: "0.00%"
			},
			"Closing Costs": {
				t: "n",
				v: element.price * 0.03,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${priceCell}*${0.03})`
			},
			"Total Renovation Cost": {
				t: "n",
				v: 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
			},
			"Rent": {
				t: "n",
				v: element.financials.rent !== null ? element.financials.rent : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Other Income": {
				t: "n",
				v: element.financials.otherIncome !== undefined ? element.financials.otherIncome : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Mortgage payment": {
				t: "n",
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(-PMT(${interestRateCell}/(${paymentMonthsCell}/${loanYearsCell}),${paymentMonthsCell},${loanAmountCell},0))`
			},
			"HOA fees": {
				t: "n",
				v: element.financials.hoaFees,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Insurance": {
				t: "n",
				v: element.financials.insurance,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Property taxes": {
				t: "n",
				v: element.financials.propertyTaxes,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Vacancy": {
				t: "n",
				v: element.financials.vacancyRateAllocation !== null ? element.financials.vacancyRateAllocation : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Management fee": {
				t: "n",
				v: element.financials.managementFee !== null ? element.financials.managementFee : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Maintenance": {
				t: "n",
				v: element.financials.maintenanceFee !== null ? element.financials.maintenanceFee : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Other costs": {
				t:"n",
				v: element.financials.otherCosts !== undefined ? element.financials.otherCosts : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Loan Years": {
				t: "n",
				v: loanYears
			},
			"Loan Amount": {
				t: "n",
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${priceCell}-${downPaymentCell})`
			},
			"Payment Months": {
				t: "n",
				v: (loanYears * 12),
				f: `(${loanYearsCell}*12)`
			},
			"Zoned for ADU": {
				t: "b",
				v: element.zonedForAdu
			},
			"Add Bedroom Opportunity": {
				t: "b",
				v: element.addBedOpportunity
			},
			"Fixer Upper": {
				t: "b",
				v: element.fixerUpper
			},
			"Est. Equity": {
				t: "n",
				v: element.ownerAttributes.estEquity,
				z: "0.00%",
				f: `(${priceCell}-${mortgageBalanceCell})/${priceCell}`
			},
			"Est. Mortgage Balance": {
				t: "n",
				v: element.ownerAttributes.estMortgageBalance,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Est. Mortgage Payment": {
				t: "n",
				v: element.ownerAttributes.estMortgagePayment,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Absentee Owner": {
				t: "b",
				v: element.ownerAttributes.absenteeOwner
			},
			"Free & Clear": {
				t: "b",
				v: element.ownerAttributes.freeClear === null ? false : element.ownerAttributes.freeClear
			},
			"Inherited": {
				t: "b",
				v: element.ownerAttributes.inherited
			},
			"Multiple Mortgages": {
				t: "b",
				v: element.ownerAttributes.multipleMortgages
			},
			"Pre-Foreclosure": {
				t: "b",
				v: element.ownerAttributes.preForeclosure
			},
			"Recent Death": {
				t: "b",
				v: element.ownerAttributes.death
			},
			"Tax Lien": {
				t: "b",
				v: element.ownerAttributes.taxLien
			},
			"Vacant": {
				t: "b",
				v: element.ownerAttributes.vacant
			},
			"Owner Name": element.listedBy !== undefined && element.listedBy !== null && element.listedBy.displayName !== undefined && element.listedBy.displayName !== null ? element.listedBy.displayName : element.listedBy !== undefined && element.listedBy !== null && element.attributionInfo.agentName !== null ? element.attributionInfo.agentName : "N/A",
			"Contact Type": "Owner",
			"Owner Phone Number": element.fsbo === true ? element.fsboOwnerPhone : "N/A",
			"Owner Email": element.attributionInfo !== undefined && element.attributionInfo !== null && element.attributionInfo.agentEmail !== undefined && element.attributionInfo.agentEmail !== null ? element.attributionInfo.agentEmail : "N/A",
			"FastPeopleSearch/OpenCorporates": skipTraceLink,
			"Parcel ID": element.parcelId !== undefined ? element.parcelId : "N/A",
			"Property Report & LoI Generator": {
				v: `http://creative.coffeeclozers.com/properties/${element.cityId}/${element.zpid}`,
				l: {
					Target: `http://creative.coffeeclozers.com/properties/${element.cityId}/${element.zpid}`
				}
			}
		};
		if ( element.zonedForAdu === undefined ) {
			delete newObject["Zoned for ADU"];
		}
		if ( element.addBedOpportunity === undefined ) {
			delete newObject["Add Bedroom Opportunity"];
		}

		data.push(newObject);
	};
	return data;
};

export const getDocument = async (colRef, docRef) => {
	const db = getFirestore();
	const query = doc(db, colRef, docRef);

	return new Promise(async resolve => {
		await getDoc(query)
		.then((doc) => {
			if ( doc.exists() ) {
				const res = {
					status: 200,
					data: doc.data(),
					message: "Document found!"
				}
				resolve(res);
			} 
			else {
				const res = {
					status: 400,
					data: [],
					message: "No such document!"
				}
				resolve(res);
			}
		})
		.catch((error) => {
			const res = {
				status: 400,
				data: [],
				message: `Error getting document: ${error}`,
				code: error.code
			}
			resolve(res);
		});
	});
};

export const setMergeData = (colRef, docRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef), {
			data
		}, {merge: true})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			console.log("Error = ", e);
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			resolve(res);
		});
	});
}