import React from 'react';
import '../styles/Error404.css';
import { Header, Footer } from '../components';

function Error404() {
    return (
        <div className="error-404-outer-container">
            <Header />
            <div className="error-404-inner-container">
                <div className="error-404-body-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center margin-x-small">
                        404
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        Page not found
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Error404;