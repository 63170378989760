import React from 'react';
import '../styles/PropertiesTable.css';
import { formatterLong, numberFormatter, percentage } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';

function PropertiesTable(props) {
    const properties = props.properties;
    const propertyLimit = props.propertyLimit;

    const columns = [
        {
            label: "Address",
            style: "u",
            value: ["address", "streetAddress"],
            cellValue: "address"
        },
        {
            label: "City",
            style: "t",
            value: ["address", "city"],
            cellValue: "city"
        },
        {
            label: "ZIP code",
            style: "t",
            value: ["address", "zipcode"],
            cellValue: "zip-code"
        },
        // {
        //     label: "State",
        //     style: "t",
        //     value: ["address", "state"],
        //     cellValue: "state"
        // },
        {
            label: "Seller Finance Lead",
            style: "b",
            value: ["sellerFinancingFreeClearFlag"],
            cellValue: "seller-finance"
        },
        {
            label: "Subto Lead",
            style: "b",
            value: ["subtoFlag"],
            cellValue: "subto"
        },
        {
            label: "Cash on cash",
            style: "p",
            value: ["financials", "cashOnCash"],
            cellValue: "cash-on-cash"
        },
        {
            label: "Monthly profit",
            style: "d",
            value: ["financials", "monthlyProfit"],
            cellValue: "profit"
        },
        {
            label: "Initial costs",
            style: "d",
            value: ["financials", "initialCosts"],
            cellValue: "initial-costs"
        },
        {
            label: "Price",
            style: "d",
            value: ["price"],
            cellValue: "price"
        },
        {
            label: "Down Payment",
            style: "dp",
            value: ["financials", "downPayment"],
            cellValue: "down-payment"
        },
        {
            label: "Closing Costs",
            style: "d",
            value: ["financials", "closingCosts"],
            cellValue: "closing-costs"
        },
        {
            label: "Rent",
            style: "d",
            value: ["financials", "rent"],
            cellValue: "rent"
        },
        {
            label: "Mortgage Payment",
            style: "d",
            value: ["financials", "mortgagePayment"],
            cellValue: "mortgage-payment"
        },
        {
            label: "HOA",
            style: "d",
            value: ["financials", "hoaFees"],
            cellValue: "hoa"
        },
        {
            label: "Insurance",
            style: "d",
            value: ["financials", "insurance"],
            cellValue: "insurance"
        },
        {
            label: "Property Taxes",
            style: "d",
            value: ["financials", "propertyTaxes"],
            cellValue: "property-taxes"
        },
        {
            label: "Vacancy",
            style: "d",
            value: ["financials", "vacancyRateAllocation"],
            cellValue: "vacancy"
        },
        {
            label: "Management fee",
            style: "d",
            value: ["financials", "managementFee"],
            cellValue: "management-fee"
        },
        {
            label: "Maintenance",
            style: "d",
            value: ["financials", "maintenanceFee"],
            cellValue: "maintenance"
        },
        {
            label: "Property type",
            style: "t",
            value: ["propertyTypeDimension"],
            cellValue: "property-type"
        },
        {
            label: "Beds",
            style: "t",
            value: ["bedrooms"],
            cellValue: "beds"
        },
        {
            label: "Baths",
            style: "t",
            value: ["bathrooms"],
            cellValue: "baths"
        },
        {
            label: "Year built",
            style: "t",
            value: ["yearBuilt"],
            cellValue: "year-built"
        },
        {
            label: "SQFT",
            style: "n",
            value: ["livingArea"],
            cellValue: "sq-footage"
        },
        {
            label: "Days on market",
            style: "n",
            value: ["dateDifference"],
            cellValue: "days-on-market"
        }
    ];

    // Style Guide
    // u = underline
    // p = percentage
    // b = badge
    // d = dollar
    // t = text

    const checkClass = (val) => {
        if ( val === "u" ) {
            return "body-regular-underline colour-link";
        }
        else if ( val === "d" || val === "dp" ) {
            return "body-regular colour-primary";
        }
        else if ( val === "p" ) {
            return "body-regular colour-primary";
        }
        else if ( val === "t" || val === "m" ) {
            return "body-regular colour-primary";
        }
        else {
            return "";
        }
    };

    const renderValue = (item, column) => {
        const propertyDP = item.financials.downPayment / 100;
        let value;
        if ( column.value.length === 2 ) {
            value = item[column.value[0]][column.value[1]];
        }
        else if ( column.value.length === 1 ) {
            value = item[column.value];
        }

        if ( column.style === "u" ) {
            return value;
        }
        else if ( column.style === "p" ) {
            return typeof(value) === "number" ? percentage.format(value / 100) : value;
        }
        else if ( column.style === "d" ) {
            return formatterLong.format(Number(value)).replace(".00", "");
        }
        else if ( column.style === "dp" ) {
            return formatterLong.format(propertyDP * item.price).replace(".00", "");
        }
        else if ( column.style === "t" ) {
            return value;
        }
        else if ( column.style === "m" ) {
            return value === true ? "Off-market" : "On-market";
        }
        else if ( column.style === "n" ) {
            return numberFormatter.format(value);
        }
        else if ( column.style === "b" ) {
            return value === true ? "TRUE" : "FALSE";
        }
    };

    // const openFastPeopleSearch = (item) => {
    //     if ( item.ownerAttributes.ownerType === "Company" ) {
    //         // corporate owner https://opencorporates.com
    //         recordEvent("Viewed Open Corporates", {});
    //         const companyName = item.ownerAttributes.ownerName.replace(/ /g, "+");
    //         const corporateLink = `https://opencorporates.com/companies?utf8=✓&utf8=✓&q=${companyName}&jurisdiction_code=&type=companies`;
    //         window.open(corporateLink, "_blank");
    //     }
    //     else {
    //         recordEvent("Viewed FastPeopleSearch", {});
    //         const street = item.address.streetAddress.toLowerCase().replace(/ /g, "-");
    //         const city = item.address.city.toLowerCase().replace(/ /g, "-");
    //         const state = item.address.state.toLowerCase();
    //         window.open(`https://www.fastpeoplesearch.com/address/${street}_${city}-${state}`, "_blank");
    //     }
    // };

    const openZillow = (item) => {
        recordEvent("View Zillow Listing", {
            zpid: item.zpid
        })
        window.open(item.zillowlistingUrl, "_blank");
    };

    return (
        <div className="properties-table-outer-container">
             <table className="properties-table-element properties-body-table">
                <thead className="properties-table-row">
                    <tr className="properties-fixed-container">
                        {
                            columns.map((item, index) =>
                                <th 
                                    className="properties-table-label-cell text-align-left"
                                    key={index}
                                >
                                    <span className="body-semibold colour-primary">
                                        {item.label}
                                    </span>
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody className="properties-table-row">
                    <tr className="properties-fixed-container">
                        {
                            properties.map((item, index) =>
                                index < propertyLimit ?
                                <td
                                    key={index}
                                    className="properties-table-value-row"
                                >
                                   {
                                        columns.map((column, i) => 
                                            <div 
                                                className="properties-table-value-cell "
                                                key={`${i}-sub`}
                                            >
                                                <span 
                                                    className={checkClass(column.style)}
                                                    onClick={() => i === 0 ? openZillow(item) : null}
                                                >
                                                    {renderValue(item, column)}
                                                </span>
                                            </div>
                                        )
                                    }
                                </td>
                                :
                                index > propertyLimit && index <= (propertyLimit + 5) ?
                                <td
                                    key={index}
                                    className="properties-table-value-row"
                                >
                                {
                                        columns.map((column, i) => 
                                            <div 
                                                className="properties-table-value-cell blurred"
                                                key={`${i}-sub`}
                                            >
                                                <span className={checkClass(column.style)}>
                                                    download me to view
                                                </span>
                                            </div>
                                        )
                                    }
                                </td>
                                :
                                null
                            )
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default PropertiesTable;